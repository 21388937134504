"use client";
import { useState, useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import { StaggerChildren } from "../FramerMotion/StaggerChildren";

const items = [
  "Select profile & template to request interviewer",
  "Wait for the interview to be happen",
  "Make informed decision with report",
];
const ImageCarousel = [
  "/LandingPage/HowItWorks/HowItWorks-1.png",
  "/LandingPage/HowItWorks/HowItWorks-2.png",
  "/LandingPage/HowItWorks/HowItWorks-3.png",
];
const steps = [
  {
    title: "Choose your technology",
    img: "/LandingPage/HowItWorks/HowItWorks-1.png",
  },
  {
    title: "Give interview",
    img: "/LandingPage/HowItWorks/HowItWorks-2.png",
  },
  {
    title: "Check your Ai report",
    img: "/LandingPage/HowItWorks/HowItWorks-3.png",
  },
];

const HowItWorks = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  const [activeStep, setActiveStep] = useState(0);

  const sectionProgress = useTransform(
    scrollYProgress,
    [0, 0.4, 0.94],
    [0, 1, 2]
  );

  useEffect(() => {
    console.log(sectionProgress);
    sectionProgress.onChange((latest) => {
      console.log(latest);
      setActiveStep(Math.floor(latest));
    });
  }, [sectionProgress]);
  return (
    <div className="flex flex-col w-full items-center justify-center gap-10 px-10 dark:text-white text-black">
      <h1 className="mt-10 sm:mt-20 font-bold text-4xl sm:text-5xl lg:text-6xl text-center tracking-tight max-w-5xl">
        Here&apos;s how it works
      </h1>
      <MobileSection />
      <div className="hidden mt-10  lg:flex justify-evenly items-start w-full">
        <div className="sticky top-[40%]">
          <div className="flex flex-col  items-start space-y-10">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`flex items-center space-x-4 cursor-pointer ${
                  activeStep === index
                    ? "dark:text-white text-black"
                    : "text-gray-500"
                }`}
              >
                <div
                  className={`w-12 h-12 text-2xl dark:text-white text-black rounded-full flex items-center justify-center border-2 ${
                    activeStep === index
                      ? "dark:border-white border-black"
                      : "border-blue-500"
                  }`}
                >
                  {index + 1}
                </div>
                <div className={`font-medium  text-2xl 2xl:text-4xl `}>
                  {step.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <motion.div
          ref={ref}
          className=" flex flex-col items-center justify-start gap-20"
        >
          {items.map((item, index) => {
            return (
              <div
                key={item}
                className="w-[500px] h-[330px] 2xl:w-[750px] 2xl:h-[530px] border-2 rounded-3xl  border-blue-500/70 bg-blue-500/20 p-2"
              >
                <Image
                  src={ImageCarousel[index]}
                  className="w-full h-full object-cover rounded-md sm:rounded-2xl"
                  width={500}
                  height={500}
                  alt="Step 1"
                />
              </div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default HowItWorks;

const MobileSection = () => {
  return (
    <div className="lg:hidden flex flex-col items-center w-full ">
      <div className="sm:mt-10 flex flex-col  items-start space-y-10">
        {steps.map((step, index) => (
          <>
            <div
              key={index}
              className={` flex items-center space-x-4 cursor-pointer 
               dark:text-white text-black
           `}
            >
              <div
                className={`h-7 w-7 sm:w-10 sm:h-10 text-lg sm:text-2xl dark:text-white text-black rounded-full flex items-center justify-center border-2 
                 border-blue-500
             `}
              >
                {index + 1}
              </div>
              <div className={`font-medium  text-2xl sm:text-4xl `}>
                {step.title}
              </div>
            </div>
            <StaggerChildren direction="left">
              <div className="w-[300px] h-[200px] sm:w-[500px] sm:h-[300px] border-2 rounded-lg sm:rounded-3xl border-blue-500/70 bg-blue-500/20 p-2">
                <Image
                  src={step.img}
                  className="w-full h-full object-cover rounded-md sm:rounded-2xl"
                  width={500}
                  height={500}
                  alt="Step 1"
                />
              </div>
            </StaggerChildren>
          </>
        ))}
      </div>
    </div>
  );
};

const Line = () => {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };
  return (
    <motion.svg
      width="200"
      height="200"
      viewBox="0 0 400 400"
      initial="hidden"
      animate="visible"
    >
      <motion.line
        strokeWidth={6}
        strokeLinecap={"round"}
        x1="50"
        y1="50"
        x2="350"
        y2="350"
        stroke="#ff0055"
        custom={0}
        variants={draw}
      />
      <motion.line
        strokeWidth={6}
        strokeLinecap={"round"}
        x1="50"
        y1="350"
        x2="350"
        y2="50"
        stroke="#ff0055"
        custom={0.5}
        variants={draw}
      />
    </motion.svg>
  );
};

"use client";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  animate,
} from "framer-motion";
import { useEffect, useState } from "react";
import Hero from "@/components/LandingPage/Hero";
import HowItWorks from "@/components/LandingPage/HowItWorks";
import EverythingYouNeedToKnow from "@/components/LandingPage/EverythingYouNeedToKnow";
import Tutorial from "@/components/LandingPage/Tutorial";
import Testimonials from "@/components/LandingPage/Testimonials";
import Faq from "@/components/LandingPage/Faq";
import Pricing from "@/components/LandingPage/Pricing";
import Footer from "@/components/Footer/Footer";
import AnimatedGradient from "@/components/AnimatedGradient";
import { useTheme } from "next-themes";

const position = ["top-[75%]"];

const App = () => {
  const { theme } = useTheme();
  const Colors = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];
  const color = useMotionValue(Colors[0]);
  const backgroundImage = useMotionTemplate`radial-gradient(185% 185% at 50% 0%, #000000 50%,${color})`;

  useEffect(() => {
    animate(color, Colors, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);
  return (
    <motion.div
      // style={{
      //   backgroundImage,
      // }}
      className="relative flex flex-col text-white gap-10 px-10 pb-10 dark:bg-black bg-muted"
    >
      {position.map((item, i) => {
        return (
          <>
            <div
              key={i}
              className={`${
                theme === "light" ? "hidden" : "block"
              }  absolute ${item} left-[30%] blur-[10rem] bg-[#101339]  min-h-[5%] min-w-[40%] rounded-full `}
            ></div>
          </>
        );
      })}

      <Hero />
      <HowItWorks />
      <EverythingYouNeedToKnow />
      <Tutorial />
      <Testimonials />
      {/* <Pricing /> */}
      <Faq />
      {/* <AnimatedGradient /> */}
      {/* <Footer /> */}
    </motion.div>
  );
};

export default App;

"use client";
import React from "react";
import { Button } from "../ui";
import Link from "next/link";
import { useSession } from "next-auth/react";
import Image from "next/image";

const Tutorial = () => {
  const { status } = useSession();
  const isAuth = status === "authenticated" ? true : false;
  return (
    <div className="flex 2xl:flex-row flex-col justify-around items-center 2xl:items-start py-10 sm:mt-10  ">
      <div className=" flex flex-col sm:mt-10   items-center 2xl:items-start">
        <h1 className="font-bold dark:text-white text-black text-4xl sm:text-4xl lg:text-6xl leading-snug">
          See for yourself.
        </h1>
        <p className="mt-5 text-center dark:text-gray-200 text-gray-600 text-lg sm:text-xl 2xl:text-2xl leading-snug">
          Try out our platform to see how it works.
        </p>
        <Link className="mt-5" href={`${isAuth ? "/interview" : "/register"}`}>
          <Button className="p-4 sm:p-8 text-xl text-white font-bold bg-gradient-to-r from-cyan-500 to-blue-500">
            Try now
          </Button>
        </Link>
      </div>
      <div className="2xl:mt-0 mt-10 w-[330px] sm:w-full sm:max-w-lg 2xl:max-w-3xl border-2 rounded-lg sm:rounded-3xl border-blue-500/70 bg-blue-500/20 p-2 sm:p-3">
        <video
          className="h-full w-full rounded-md sm:rounded-2xl "
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src="/LandingPage/Tutorial/inquisai_tutorial.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Tutorial;

"use client";
import React from "react";
import { StaggerChildren } from "../FramerMotion/StaggerChildren";
import { useTheme } from "next-themes";

const items = [
  "Q&A Training",
  "10000+ Interview Questions",
  "Widey Range Of Topics",
  "System Design",
  "Core Subjects",
  "Instant AI Feedback",
  "Detailed  Analysis & Explanation",
  "Behavioral Interview Coaching",
  "Company-Specific Insights",
];
const benefits = [
  {
    heading: "Interview Sessions",
    title: "Expert Mentor",
    desc: `Vet candidates' tech skills in just 15-30 minutes with AI-generated, skill-specific interviews.`,
  },
  {
    heading: "Tailored Feedback",
    title: "Personalized Insights",
    desc: `Unlock your full potential with personalized feedback from our AI mentor. `,
  },
  {
    heading: "Real-Time Simulation",
    title: "True-to-Life Scenarios",
    desc: `Prepare for your tech interviews with realistic, domain-specific AI simulations.`,
  },
];
const EverythingYouNeedToKnow = () => {
  const { theme } = useTheme();

  return (
    <div className="flex flex-col justify-center items-center py-10 mt-5 sm:mt-10 dark:text-white text-black ">
      <h1 className="font-bold text-4xl sm:text-4xl lg:text-6xl text-center tracking-tight max-w-5xl">
        Everything you need to prepare for your next interview
      </h1>
      <p className="mt-10 max-w-4xl text-center text-base font-normal dark:text-gray-300 text-gray-600 mx-auto  sm:text-xl lg:text-2xl">
        Our AI Assistant offers comprehensive and in-depth interview practice
        sessions, paving the way for interview mastery and enhancing your
        confidence in facing any interview scenario.
      </p>
      <div className="mt-10 sm:mt-20 flex lg:flex-row flex-col items-center justify-center gap-10">
        {benefits.map((item, i) => {
          return (
            <>
              <StaggerChildren
                key={i}
                direction="down"
                delay="2"
                className={`h-[320px] max-w-md hover:scale-105 transition-all flex flex-col justify-center items-start p-8 rounded-2xl border border-blue-500/70 bg-gradient-to-b ${
                  theme === "dark"
                    ? "from-black to-cyan-500/10"
                    : " from-muted to-blue-500/10"
                } gap-5 md:gap-8`}
              >
                <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 text-3xl 2xl:text-5xl font-[1000]">
                  {item.heading}
                </h1>
                <h1 className="text-xl sm:text-xl md:text-2xl font-semibold">
                  {item.title}
                </h1>
                <p className="md:text-lg font-medium">{item.desc}</p>
              </StaggerChildren>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default EverythingYouNeedToKnow;

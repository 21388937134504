"use client";
// components/AnimatedGradient.js
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  animate,
} from "framer-motion";
import { useEffect, useState } from "react";

const AnimatedGradient = () => {
  const Colors = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];
  const color = useMotionValue(Colors[0]);
  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #000000 50%,${color})`;

  useEffect(() => {
    animate(color, Colors, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);

  return (
    <motion.div
      style={{
        backgroundImage,
      }}
      className="h-[500px] absolute inset-0  grid place-content-center overflow-hidden bg-black"
    />
  );
};

export default AnimatedGradient;

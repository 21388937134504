"use client";
import { useState, useRef, useEffect } from "react";
import SearchBarJobProfile from "./SearchBar";
import Image from "next/image";
import Link from "next/link";
const users = [
  {
    name: "sushant dey",
    role: "Full Stack developer",
    company: "Delhi Technological University",
    videoSrc: "/LandingPage/example.mp4",
    imageSrc: "/google.svg",
  },
  {
    name: "priyanshu rawat",
    role: "Full Stack developer",
    company: "Delhi Technological University",
    videoSrc: "/LandingPage/example.mp4",
    imageSrc: "/google.svg",
  },
  {
    name: "Dishant Kapoor",
    role: "Full Stack developer",
    company: "Delhi Technological University",
    videoSrc: "/LandingPage/example.mp4",
    imageSrc: "/google.svg",
  },
  {
    name: "Cherish Sachdeva",
    role: "Full Stack developer",
    company: "Delhi Technological University",
    videoSrc: "/LandingPage/example.mp4",
    imageSrc: "/google.svg",
  },
];
const Home = () => {
  const [user, setUser] = useState({
    name: "Sushant Dey",
    role: users[0].role,
    company: users[0].company,
    videoSrc: users[0].videoSrc,
  });
  return (
    <>
      <div className="flex flex-col justify-center items-center mt-10  sm:mt-20 dark:text-white text-black">
        <h1 className="font-bold text-4xl sm:text-5xl lg:text-6xl text-center tracking-tight max-w-5xl ">
          <span className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
            AI-Powered
          </span>{" "}
          Interviews To Unlock Your Dream Career
        </h1>
        <p className="max-w-3xl text-center text-base font-normal dark:text-gray-300 text-gray-600 mx-auto mt-5 sm:text-lg lg:text-xl">
          Meet Your AI Interview Coach. The Fastest Way to Excel in Your Job
          Interviews. Our AI Assistant Provides In-depth Practice and Insights
          for Interview Mastery.
        </p>
        <div className="my-10">
          <SearchBarJobProfile />
        </div>
        <div className="flex lg:flex-row flex-col justify-around items-center lg:items-start w-full mt-8">
          <div className="w-[330px] sm:w-full sm:max-w-xl 2xl:max-w-7xl border-2 rounded-lg sm:rounded-3xl border-blue-500/70 bg-blue-500/20 p-2 sm:p-5 ">
            <video
              className="w-full rounded-md sm:rounded-2xl "
              controls
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={'https://ai-interview.s3.ap-south-1.amazonaws.com/HeroVideo.mp4'} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          {/* <div className="flex flex-col mt-10 lg:mt-0 2xl:mt-10 items-center lg:items-start text-center ">
            <h1 className=" text-xl sm:text-2xl lg:text-3xl 2xl:text-4xl leading-snug">
              Interview is{" "}
              <span className="font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
                Reliable, Fast
              </span>{" "}
              and{" "}
              <span className="font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
                Helpful
              </span>
            </h1>
            <p className="mt-5 text-xl sm:text-2xl 2xl:text-3xl leading-snug">
              We didn&apos;t say it. Our customers did.
            </p>
            <div className="lg:ml-3 flex justify-start items-center gap-5 lg:gap-10">
              {users.map((item) => {
                const { name, role, company, imageSrc, videoSrc } = item;
                return (
                  <>
                    <Image
                      onClick={() => {
                        setUser({
                          name: name,
                          role: role,
                          company: company,
                          videoSrc: videoSrc,
                        });
                      }}
                      src={imageSrc}
                      className="mt-10 cursor-pointer rounded-full max-w-[40px] sm:max-w-[60px] aspect-square shadow-md bg-white border"
                      width={100}
                      height={100}
                      alt={name}
                    />
                  </>
                );
              })}
            </div>
            <div className=" mt-7 lg:mt-5 lg:ml-5 flex flex-col items-center lg:items-start justify-center gap-1">
              <h1 className="capitalize text-xl lg:text-2xl font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
                {user?.name}
              </h1>
              <p className="capitalize font-semibold">
                {user?.role}, {user?.company}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;

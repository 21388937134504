"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useDispatch } from "react-redux";
import {
  setInterviewLevel,
  setInterviewCategory,
  setInterviewSubCategory,
  setInterviewSubCategoryImage,
  resetInterviewSubCategory,
} from "@/features/interview/interviewSlice";
import { useRouter } from "next/navigation";

const profiles = [
  { name: "Frontend", src: "/LandingPage/Search/frontend.svg" },
  { name: "Backend", src: "/LandingPage/Search/backend.svg" },
  { name: "Cloud", src: "/LandingPage/Search/cloud.svg" },
  { name: "Mobile", src: "/LandingPage/Search/mobile.svg" },
  { name: "Programming", src: "/LandingPage/Search/programming.svg" },
  { name: "View all", src: "/LandingPage/Search/add.svg" },
];
const SearchBarJobProfile = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <div className="grid place-content-center grid-cols-3 lg:grid-cols-6 gap-4">
      {profiles.map((profile, index) => {
        return (
          <div key={index} className="">
            <Link
              className="cursor-pointer flex flex-col justify-center items-center gap-3"
              href="/interview"
              onClick={() => {
                if (profile.name === "View all") {
                  router.push("/interview");
                } else {
                  dispatch(setInterviewCategory(profile.name));
                }
              }}
            >
              <Image
                className=" transition-all hover:scale-105 sm:max-w-[120px] md:max-w-[150px] h-[80px]"
                src={profile.src}
                alt={profile}
                width={200}
                height={200}
              />
              <p className="capitalize text-lg sm:text-xl font-semibold">
                {profile.name}
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SearchBarJobProfile;

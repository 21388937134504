import { FramerAnimateOV } from "./TextAppers";

export function StaggerChildren({ children, delay, className, direction }) {
  const maxDelay = delay >= 5 ? 5 : delay;
  return (
    <FramerAnimateOV
      className={className}
      delay={maxDelay * 0.1}
      direction={direction || "up"}
    >
      {children}
    </FramerAnimateOV>
  );
}

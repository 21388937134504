"use client";
import { useState } from "react";
import { Card, CardTitle, CardHeader, CardDescription } from "../ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useTheme } from "next-themes";
const testimonials = [
  {
    name: "Yuvraj Singh",
    company: "Delhi Technological University",
    image: "/LandingPage/Testimonials/Yuvraj.jpg",
    quote:
      "I cannot express how much this platform helped me prepare for my interviews. The feedback was spot-on, and it boosted my confidence",
  },
  {
    name: "Pulkit Kumar",
    company: "Cloudfreeks!",
    image: "/LandingPage/Testimonials/Pulkit.jpeg",
    quote:
      "As a recent graduate, this tool was a game-changer for me. The personalized interview practice was invaluable in securing my dream job.",
  },
  {
    name: "Dishant Kapoor",
    company: "Shine Web Solutions",
    image: "/LandingPage/Testimonials/Dishant.jpeg",
    quote:
      "This platform made interview prep much less daunting. The company-specific insights were a lifesaver. Thank you for a fantastic service!",
  },
  // {
  //   name: "Bob",
  //   company: "Company D",
  //   quote:
  //     "I'm amazed by the depth of features on this platform. The behavioral interview coaching was incredibly helpful, and it boosted my confidence during real interviews.",
  // },
];

const Testimonials = () => {
  const { theme } = useTheme();
  return (
    <div className="flex flex-col justify-center items-center py-7  sm:mt-10 dark:text-white text-black">
      <h1 className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 font-bold text-4xl sm:text-4xl lg:text-6xl text-center tracking-tight max-w-5xl">
        Customer Testimonials
      </h1>
      <p className="mt-5 max-w-4xl text-center text-base font-bold  mx-auto  sm:text-xl lg:text-2xl">
        Let the results speak for themselves.
      </p>
      <div className="mt-10 sm:mt-40 hidden  lg:grid place-items-center grid-cols-1 md:grid-cols-2 gap-6">
        {testimonials.map((item, index) => (
          <Card
            className={`p-5 border border-blue-500/70 bg-gradient-to-b ${theme === "dark"
              ? "from-black to-cyan-500/10"
              : " from-muted to-blue-500/10"
              }  ${index % 4 === 0
                ? "transform translate-x-0 translate-y-[-49.46px] "
                : index % 4 === 1
                  ? "transform  translate-y-10 translate-x-[20px]"
                  : index % 4 === 2
                    ? "transform translate-y-[60px] translate-x-[150px]  "
                    : "transform translate-x-[-100px] translate-y-0 "
              }`}
            key={index}
          >
            <CardHeader className="h-[180px]  max-w-xl mb-4">
              <CardDescription className="text-lg sm:text-2xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 ">
                {item.quote}
              </CardDescription>
            </CardHeader>
            <div className="flex items-center space-x-5 ml-5">
              <Avatar>
                <AvatarImage
                  className="object-cover"
                  src={item.image}
                  alt="@shadcn"
                />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <div className="flex flex-col justify-center">
                <CardTitle className="font-bold text-base">
                  {item.name}
                </CardTitle>
                <CardTitle className="text-sm dark:text-gray-400 text-gray-600">
                  {item.company}
                </CardTitle>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <div className="lg:hidden block mt-10">
        <Carousel className="2xl:hidden block  max-w-[265px] sm:max-w-xl">
          <CarouselContent className="">
            {testimonials.map((item, index) => (
              <CarouselItem key={index}>
                <div
                  className={`h-[290px] sm:h-[220px] p-5 rounded-2xl border border-blue-500/70 bg-gradient-to-b  ${theme === "dark"
                    ? "from-black to-cyan-500/10"
                    : " from-muted to-blue-500/10"
                    }`}
                >
                  <div className="w-full">
                    <p className="text-lg inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
                      {item.quote}
                    </p>
                  </div>
                  <div className="flex items-center space-x-5 mt-5">
                    <Avatar>
                      <AvatarImage
                        className="object-cover"
                        src={item.image}
                        alt="@shadcn"
                      />
                      <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col justify-center">
                      <h1 className="font-bold text-base">{item.name}</h1>
                      <h1 className="text-sm dark:text-gray-400 text-gray-600">
                        {item.company}
                      </h1>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;

"use client";
import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useTheme } from "next-themes";

const Faq = () => {
  const { theme } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(null);
  const faq = [
    {
      question: "How does the AI interview preparation work?",
      answer:
        "Our AI-powered platform provides realistic mock interviews with tailored questions and immediate feedback to help you improve your interview skills.",
    },
    {
      question: "Is there a cost associated with using your service?",
      answer:
        "We offer both free and paid plans. You can check out our fee demo and later choose a subscription that suits your needs.",
    },
    {
      question: "Do I need to download any software for the mock interviews?",
      answer:
        "No downloads are required. You can access our platform online through your web browser. All you need is a camera and microphone",
    },
    {
      question: "Can I practice for specific job roles and industries?",
      answer:
        "Yes, our platform offers interview scenarios customized for various job roles and industries.",
    },
    // {
    //   question: "How do I receive feedback on my mock interviews?",
    //   answer:
    //     "You'll receive immediate AI-driven feedback on your interview responses, highlighting areas for improvement.",
    // },
    // {
    //   question: "Can I practice interviews in multiple languages?",
    //   answer:
    //     "No, our platform currently only supports english, we are working on it to make it accessable in multiple languages.",
    // },
  ];
  return (
    <div className="flex flex-col justify-center items-center py-7 dark:text-white text-black mt-5 sm:mt-10 ">
      <h1 className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 font-bold text-4xl sm:text-4xl lg:text-6xl text-center tracking-tight max-w-5xl">
        FAQs
      </h1>
      <p className="mt-5 max-w-4xl text-center text-base font-bold  mx-auto  sm:text-xl lg:text-2xl">
        Have a different question and can&apos;t find the answer you&apos;re
        looking for? Reach out to our support team by sending us an email and
        we&apos;ll get back to you as soon as we can.
      </p>
      <div className="mt-10 flex flex-col justify-center items-center w-full max-w-4xl gap-8">
        {faq.map((item, index) => {
          const { question, answer } = item;
          return (
            <>
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem
                  className={`rounded-3xl p-3 md:p-10 border border-blue-500/70 bg-gradient-to-b  ${
                    theme === "dark"
                      ? "from-black to-cyan-500/10"
                      : " from-muted to-blue-500/10"
                  }`}
                  value="item-1"
                >
                  <AccordionTrigger className="text-sm sm:text-2xl font-semibold hover:no-underline">
                    {question}
                  </AccordionTrigger>
                  <AccordionContent className="dark:text-gray-300 text-gray-600 text-sm sm:text-xl  font-normal text-center md:text-left">
                    {answer}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
